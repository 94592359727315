import axios from 'axios'

class MasterDataFactory {

  async master_datas (data: any) {
    try {
      let url = '/master_datas'
      var response = await axios.get(url, {
          params: {
            school_years: data.school_years,
            bill_types: data.bill_types,
            bill_detail_types: data.bill_detail_types,
            classes: data.classes,
            months: data.months,
            school: data.school,
            math_operations: data.math_operations,
            order_statuses: data.order_statuses,
            convenience_fee: data.convenience_fee
          }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async setting_data (data: any) {
    try {
      let url = '/setting_data'
      var response = await axios.get(url, {
          params: {
            title: data.title,
          }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

}


export let masterDataFactory = new MasterDataFactory()
